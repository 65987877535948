<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Logistic Partner</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <form>
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="name">Name</label>
                                                <input type="text" id="name" v-model="search.name" placeholder="Enter Name" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <div class="form-group">
                                                <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm"> 
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Website</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Website</th>
                                                    <th style="text-align:center;">Action</th>
                                                </tr>
                                            </tfoot>
                                            <tbody>
                                                <tr v-for="(item, key) in listData" :key="key">
                                                    <td>{{ item.name }}</td>
                                                    <td>{{ item.website }}</td>
                                                    <td style="text-align:center;">
                                                        <button class="btn btn-warning btn-sm mr-1" @click="editBank(item)"><i class="fas fa-pencil-alt"></i></button>
                                                        <button class="btn btn-success btn-sm" title="Login" @click="login()"><i class="fas fa-sign-in-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>
                                    <div class="mt-3">
                                        <b-pagination
                                            v-model="pagination.currentPage"
                                            :per-page="pagination.perPage"
                                            :total-rows="pagination.totalRows"
                                            @input="searchData"
                                        />
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <div v-if="createModal" class="modal fade show" style="display:block" tabindex="-1" id="addBankModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Create New Logistic Partner</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                             <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(storeData)">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name" v-model="form.name" class="form-control" placeholder="Name">
                                                <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="email">Email <span class="text-danger" title="Required">*</span></label>
                                                <input type="email" id="email" v-model="form.email" class="form-control" placeholder="Email">
                                                <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="mobile" v-model="form.mobile" class="form-control" placeholder="Mobile">
                                                <span class="text-danger" v-if="errors.mobile">{{ errors.mobile[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="mobile">Website <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="website" v-model="form.website" class="form-control" placeholder="Website">
                                                <span class="text-danger" v-if="errors.website">{{ errors.website[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="address" v-model="form.address" class="form-control" placeholder="Address">
                                                <span class="text-danger" v-if="errors.address">{{ errors.address[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="base_url">Base URL <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="base_url" v-model="form.base_url" class="form-control" placeholder="Base URL">
                                                <span class="text-danger" v-if="errors.base_url">{{ errors.base_url[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="client_id">Client ID <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="client_id" v-model="form.client_id" class="form-control" placeholder="Client ID">
                                                <span class="text-danger" v-if="errors.client_id">{{ errors.client_id[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="client_secret">Client Secret <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="client_secret" v-model="form.client_secret" class="form-control" placeholder="Client Secret">
                                                <span class="text-danger" v-if="errors.client_secret">{{ errors.client_secret[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="access_token">Access Token <span class="text-danger" title="Required">*</span></label>
                                                <textarea id="access_token" v-model="form.access_token" class="form-control" placeholder="Access Token"> </textarea>
                                                <span class="text-danger" v-if="errors.access_token">{{ errors.access_token[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="refresh_token">Refresh Token <span class="text-danger" title="Required">*</span></label>
                                                <textarea id="refresh_token" v-model="form.refresh_token" class="form-control" placeholder="Refresh Token"> </textarea>
                                                <span class="text-danger" v-if="errors.refresh_token">{{ errors.refresh_token[0] }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>

                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="storeData">Save</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="editModal" class="modal fade show" style="display:block" tabindex="-1" id="editBankModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Edit Bank</h5>
                            <button type="button" class="close" aria-label="Close" @click="cancelModal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                             <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                                <form @submit.prevent="handleSubmit(updateData)">
                                    <div class="row">
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="name">Name <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="name" v-model="form_edit.name" class="form-control" placeholder="Name">
                                                <input type="hidden" name="id" v-model="form_edit.id" />
                                                <span class="text-danger" v-if="errors.name">{{ errors.name[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="email">Email <span class="text-danger" title="Required">*</span></label>
                                                <input type="email" id="email" v-model="form_edit.email" class="form-control" placeholder="Email">
                                                <span class="text-danger" v-if="errors.email">{{ errors.email[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="mobile">Mobile <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="mobile" v-model="form_edit.mobile" class="form-control" placeholder="Mobile">
                                                <span class="text-danger" v-if="errors.mobile">{{ errors.mobile[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="mobile">Website <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="website" v-model="form_edit.website" class="form-control" placeholder="Website">
                                                <span class="text-danger" v-if="errors.website">{{ errors.website[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                            <div class="form-group">
                                                <label for="address">Address <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="address" v-model="form_edit.address" class="form-control" placeholder="Address">
                                                <span class="text-danger" v-if="errors.address">{{ errors.address[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="base_url">Base URL <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="base_url" v-model="form_edit.base_url" class="form-control" placeholder="Base URL">
                                                <span class="text-danger" v-if="errors.base_url">{{ errors.base_url[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="client_id">Client ID <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="client_id" v-model="form_edit.client_id" class="form-control" placeholder="Client ID">
                                                <span class="text-danger" v-if="errors.client_id">{{ errors.client_id[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="client_secret">Client Secret <span class="text-danger" title="Required">*</span></label>
                                                <input type="text" id="client_secret" v-model="form_edit.client_secret" class="form-control" placeholder="Client Secret">
                                                <span class="text-danger" v-if="errors.client_secret">{{ errors.client_secret[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="access_token">Access Token <span class="text-danger" title="Required">*</span></label>
                                                <textarea id="access_token" v-model="form_edit.access_token" class="form-control" placeholder="Access Token"> </textarea>
                                                <span class="text-danger" v-if="errors.access_token">{{ errors.access_token[0] }}</span>
                                            </div>
                                        </div>
                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div class="form-group">
                                                <label for="refresh_token">Refresh Token <span class="text-danger" title="Required">*</span></label>
                                                <textarea id="refresh_token" v-model="form_edit.refresh_token" class="form-control" placeholder="Refresh Token"> </textarea>
                                                <span class="text-danger" v-if="errors.refresh_token">{{ errors.refresh_token[0] }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click.prevent="updateData">Update</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" id="deleteBankModal" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <b-overlay :show="loader">
                        <div class="modal-header bg-info text-white">
                            <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                        </div>
                        <div class="modal-body">
                            <input type="hidden" name="del_id" />
                            <h3 class="text-center">Are you sure to delete ?</h3>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-info" @click="destroyBank">Delete</button>
                            <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                        </div>
                    </b-overlay>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
import config from '@/config'
export default {
    name:'Bank',
    data(){
        return {
            loader: false,
            form:{
                name : '',
                mobile : '',
                email : '',
                website : '',
                address : '',
                base_url : '',
                client_id : '',
                client_secret : '',
                access_token : '',
                refresh_token : ''
            },
            search: {
                name: ''
            },
            form_edit:'',
            errors:[],
            createModal:false,
            editModal:false,
            deleteModal:false,
            bank_id:'',
            key:'',
            pagination: {
                currentPage: 1,
                totalRows: 0,
                perPage: this.$store.state.commonObj.perPage,
                slOffset: 1
            }
        }
    },
    created(){
        this.loadData()
    },
    watch: {
        stateReload : function (newVal, oldVal) {
            if (newVal != oldVal) {
                this.loadData()
            }
        }
    },
    computed : {
        stateReload () {
            return this.$store.state.stateReload
        },
        listData () {
            return this.$store.state.list
        }
    },
    methods:{
        searchData () {
            this.loadData()
        },
        async loadData(){     
            this.loader = true 
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
            const response = await config.getData('/logistic-partner', params)
            this.loader = false
            console.log('res: ', response)
            if (response.success){
                this.$store.dispatch('setList', response.data.data)
                this.paginationData(response.data)
            } else {
                this.$store.dispatch('setList', [])
            } 
        },
        paginationData (data) {
            this.pagination.currentPage = data.current_page
            this.pagination.totalRows = data.total
            this.pagination.perPage = data.per_page
            this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
        },
        showCreateModal(){
            this.createModal = true;
        },
        async storeData(){
            this.loader = true
            const response = await config.postData('/logistic-partner/store', this.form)
            this.loader = false
            if (response.success) {
                this.$store.dispatch('commonObjLoad', true)
                this.createModal = false;
                this.form = ''           
                this.$toast.success({
                    title: 'Success',
                    message: 'Data savve successfully',
                    color: '#218838'
                }) 
                this.loadData();
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong'
                })   
            }
        },
        cancelModal(){
            this.createModal = false;
            this.editModal = false;
            this.deleteModal = false;
        },
        editBank(bank){
            this.editModal = true;
            this.form_edit = bank;
        },
        async updateData(){
            this.loader = true
            const response = await config.postData('/logistic-partner/update', this.form_edit)
            this.loader = false
            if (response.success) {
                this.$store.dispatch('commonObjLoad', true)
                this.editModal = false;            
                this.$toast.success({
                    title: 'Success',
                    message: 'Bank updated successfully',
                    color: '#218838'
                })  
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong'
                })  
            }
        },
        deleteBank(key, bank_id){
            this.bank_id = bank_id;
            this.key = key;
            this.deleteModal = true;
        },
        async destroyBank(){
            this.loader = true
            await config.deleteData('/logistic-partner/destroy/' + this.bank_id) 
            this.loader = false           
            this.deleteModal = false
            this.$toast.success({
                title: 'Success',
                message: 'Bank deleted successfully',
                color: '#218838'
            }) 
        },
        login(){     
            this.loader = true 
            const response = config.getData('/logistic-partner/login', {})
            this.loader = false
            if (response.success){
                this.$toast.success({
                    title: 'Success',
                    message: 'Logged in successfully',
                    color: '#218838'
                }) 
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, something went wrong'
                }) 
            } 
        },
    }
}
</script>